import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import Animate from '../../../../../components/Animate.jsx';
import AnimateChangeUp from '../../../../../components/AnimateChangeUp.jsx';
import Button from '../../../../../components/Button.jsx';
import Checkbox from '../../../../../components/Checkbox.jsx';
import Icon from '../../../../../components/Icon.jsx';
import Link from '../../../../../components/Link.jsx';
import ListScroll from '../../../../../components/ListScroll.jsx';
import Loader from '../../../../../components/Loader.jsx';
import ExecutorOrg from '../../../../../components/crm/ExecutorOrg.jsx';
import CorporationInfo from '../../../../../components/crm/manual/CorporationInfo.jsx';
import Table from '../../../../../components/crm/manual/Table.jsx';
import WidgetFilter from '../../../../../components/crm/widget/Filter.jsx';
import More from '../../../../../components/crm/widget/More.jsx';

import handlerPopup from '../../../../../functions/app/handlerPopup';
import checkPaysErrors from '../../../../../functions/crm/checkPaysErrors';
import setPermissions from '../../../../../functions/crm/setPermissions';
import download from '../../../../../functions/download';
import getEndText from '../../../../../functions/getEndText';
import getFormatPrice from '../../../../../functions/getFormatPrice';
import getFormatedDate from '../../../../../functions/getFormatedDate';
import handlerSupportCursor from '../../../../../functions/handlerSupportCursor';
import handlerWindow from '../../../../../functions/handlerWindow';

import HandlerFilter from '../../../../../classes/Filter';
import getFilter from '../../../../../requests/getFilter';
import getPays from '../../../../../requests/getPays';

class PayGroupsInnerMain extends Table {
    constructor(props) {
        super(props);
        this.state = {
            currentItems: [],
        };

        this.renderRow = this.renderRow.bind(this);
        this.handlerCurrent = this.handlerCurrent.bind(this);
        this.filterCallback = this.filterCallback.bind(this);
        this.deleteItems = this.deleteItems.bind(this);
        this.handlerLoaderList = this.handlerLoaderList.bind(this);
        this.handlerSocket = this.handlerSocket.bind(this);
        this.updatePay = this.updatePay.bind(this);
        this.updatePays = this.updatePays.bind(this);
        this.handlerMore = this.handlerMore.bind(this);
        this.setPay = this.setPay.bind(this);
        this.handlerExport = this.handlerExport.bind(this);

        setPermissions.call(this);

        this.parent = React.createRef();
    }

    isNotStartGetItems = true;

    stepCounter = 25;

    renderStatus({ key, item }) {
        const { loadingKey } = this.state;
        let { status } = item;
        const statusContent = (key === 'sign' ? item.signStatus : item.actStatus)?.replace(
            '\n',
            '<br/>',
        );
        let infoContent = '';

        if (!key) {
            if (status === 'wait') {
                if (item.isCorporationSign === true) {
                    status = 'accept';
                }
            }

            if (status === 'pending' || status === 'in_progress') {
                status = 'pending';
            }

            if (status === 'error' || status === 'failed') {
                status = 'error';
            }

            if (item.isUpdate) {
                status = 'updatedCard';
            }

            if (status === 'error') {
                status = 'error';
            }

            if (item.errorText) {
                infoContent += item.errorText.replace(/\n/gi, '<br/>');
            }

            if (item.transactionErrorText) {
                if (infoContent) {
                    infoContent += '<br/>';
                }

                infoContent += item.transactionErrorText.replace(/\n/gi, '<br/>');
            }

            if (item.status === 'completed') {
                status = 'completed';
                infoContent = null;

                if (item.payDate) {
                    infoContent = getFormatedDate({ date: new Date(item.payDate), type: 'full' });
                }
            }
        }

        if (key === 'sign') {
            if (item.isCorporationSign === true) {
                status = 'accept';
            }

            if (
                item.isExecutorSign === true ||
                (item.executorSign.isNotSign && item.isCorporationSign)
            ) {
                status = 'completed';
            }
        }

        const fileName = item[key === 'sign' ? 'actFileName' : 'receiptFileName'];

        const renderFile = ({ fileKey }) =>
            fileName && (
                <div
                    className="settingsUsersTable__paysStatusButton _row _click"
                    onClick={() => {
                        this.download({
                            id: item._id,
                            key: fileKey,
                            fileName,
                        });
                    }}
                >
                    <Animate
                        className="settingsUsersTable__paysStatusButtonLoader _loader"
                        isShow={loadingKey === `${fileKey}-${item._id}`}
                    >
                        <div className="settingsUsersTable__paysStatusButtonLoaderItem _loaderItem">
                            <Loader className="_main" />
                        </div>
                    </Animate>
                    Скачать
                    <i className="settingsUsersTable__paysStatusButtonIcon">
                        <Icon name={key === 'sign' ? 'pays-sign' : 'pays-status'} />
                    </i>
                </div>
            );

        const renderKey =
            key === 'sign'
                ? `${item.isCorporationSign}${item.isExecutorSign}${item.actFileName}`
                : `${status}${item.isCorporationSign}${item.receiptFileName}${item.fnsReceiptLink}`;

        return (
            <AnimateChangeUp
                className="settingsUsersTable__paysStatus _col"
                renderKey={renderKey}
                parentStyles={['height']}
            >
                <>
                    <div className={`settingsUsersTable__paysStatusInner _row _${status}`}>
                        <div
                            className="settingsUsersTable__paysStatusInnerItem"
                            dangerouslySetInnerHTML={{ __html: statusContent }}
                        />
                        {infoContent && (
                            <i
                                className="settingsUsersTable__paysStatusInfo _click"
                                onMouseEnter={(e) => {
                                    handlerSupportCursor({
                                        action: 'enter',
                                        content: infoContent,
                                        e,
                                        data: { className: '_center' },
                                    });
                                }}
                                onMouseLeave={(e) => {
                                    handlerSupportCursor({ action: 'leave', e });
                                }}
                            >
                                <Icon name="info" />
                            </i>
                        )}
                    </div>
                    {renderFile({
                        fileKey: key === 'sign' ? 'downloadAct' : 'downloadReceipt',
                        fileName,
                    })}
                    {key !== 'sign' && item.fnsReceiptLink && this.renderFns({ item, fileName })}
                </>
            </AnimateChangeUp>
        );
    }

    renderFns({ item }) {
        const { fnsReceiptStatus, fnsReceiptLink } = item;
        const contents = {
            cancel: 'Аннулирован',
            offline: 'Оффлайн',
        };
        const supportContent = `${contents[fnsReceiptStatus] || 'Актуален'}`;
        const link =
            fnsReceiptStatus === 'offline'
                ? `${process.env.REACT_APP_STATIC}/corporations-docs/${fnsReceiptLink}`
                : fnsReceiptLink;

        return (
            <a
                className={`settingsUsersTable__paysStatusButton _row _fns _${fnsReceiptStatus}`}
                rel="noreferrer"
                href={link}
                target="_blank"
                onMouseEnter={(e) => {
                    handlerSupportCursor({
                        action: 'enter',
                        content: supportContent,
                        e,
                        data: { className: '_center _normalCase' },
                    });
                }}
                onMouseLeave={(e) => {
                    handlerSupportCursor({ action: 'leave', e });
                }}
                onClick={(e) => {
                    handlerSupportCursor({ action: 'leave', e });
                }}
            >
                ФНС чек
                <i className="settingsUsersTable__paysStatusButtonIcon">
                    <Icon name="fns" />
                </i>
            </a>
            // <a
            //     className={`settingsUsersTable__paysStatusFns _${fnsReceiptStatus} ${
            //         !fileName ? '_one' : ''
            //     }`}

            // >
            //     <i className="settingsUsersTable__paysStatusFnsIcon">
            //         <Icon name="fns" />
            //     </i>
            // </a>
        );
    }

    handerLoading(loadingKey) {
        return new Promise((resolve) => {
            this.setState({ loadingKey }, resolve);
        });
    }

    async download({ id, key, fileName }) {
        if (fileName && !this.state.loadingKey) {
            await this.handerLoading(`${key}-${id}`);

            await download({ files: [{ src: fileName, folder: 'corporations-docs' }] });

            await this.handerLoading(null);
        }
    }

    handlerCurrent(id) {
        const { allPays } = this.state;

        this.setState((state) => {
            const newState = { ...state };
            let currentItems = [...newState.currentItems];

            if (id === 'all') {
                const allWaitPays = allPays.filter(
                    (pay) => pay.status === 'wait' && pay.isCorporationSign,
                );
                const allSignedPays = allPays.filter(
                    (pay) => pay.status === 'wait' && !pay.isCorporationSign,
                );
                const allErrorPays = allPays.filter((pay) => checkPaysErrors({ pay }));
                const targetPays =
                    allSignedPays.length > 0
                        ? allSignedPays
                        : allWaitPays.length > 0
                          ? allWaitPays
                          : allErrorPays;

                currentItems = targetPays.every(({ _id: innerId }) =>
                    currentItems?.includes(innerId),
                )
                    ? []
                    : targetPays.map(({ _id }) => _id);
            } else {
                const index = currentItems.indexOf(id);

                if (index === -1) {
                    currentItems.push(id);
                } else {
                    currentItems.splice(index, 1);
                }
            }

            newState.currentItems = currentItems;

            return newState;
        });
    }

    getActionsItems({ pay }) {
        const { executorId } = this.props;
        const items = ['logs'];

        if (
            !executorId &&
            this.getPermissions({
                notAdminCorporation: true,
                key: 'pays',
                items: [
                    {
                        key: 'acts',
                        rules: ['create'],
                    },
                ],
            })
        ) {
            items.unshift('repeatPay');
        }

        if (
            !pay.wasStart &&
            !pay.transactionExecutorId &&
            !pay.isCorporationSign &&
            !pay.isExecutorSign &&
            this.getPermissions({
                notAdminCorporation: true,
                key: 'pays',
                items: [
                    {
                        key: 'acts',
                        rules: ['update'],
                    },
                ],
            })
        ) {
            items.unshift('editPay');
        }

        if (
            pay.status === 'error' &&
            this.getPermissions({
                notAdminCorporation: pay.type !== 'tax',
                key: 'pays',
                items: [
                    {
                        key: 'acts',
                        rules: [],
                        actions: ['checkError'],
                    },
                ],
            })
        ) {
            items.unshift('checkPayError');
        }

        if (
            this.getPermissions({ forHolder: true }) &&
            pay.status === 'completed' &&
            pay.isNonResident
        ) {
            items.unshift('createNonResidentAct');
        }

        return items;
    }

    handlerMore({ target, _id }) {
        const { items = [] } = this.state;
        const { getParent, isMain } = this.props;
        const parent = getParent();
        const pay = items.find((innerItem) => innerItem._id === _id);
        const actionsItems = this.getActionsItems({ pay });

        handlerWindow({
            parent,
            parentResize: parent,
            parentScroll: parent.querySelector('.settingsUsersTable__content'),
            target: target.closest('.settingsUsersTable__more'),
            action: 'show',
            name: 'editActions',
            className: '_actions _right',
            uniqKey: `pays-${_id}`,
            items: actionsItems,
            pay,
            groupId: !isMain ? pay.groupId : null,
            centers: {
                left: 0.5,
                top: 0,
            },
            isHideFromScroll: true,
            isCheckScrollPosition: false,
            updatePay: ({ pay: updatedPay }) => {
                this.updateItem({ id: pay._id, fields: updatedPay });
            },
        });
    }

    renderRow({ item }) {
        const { currentItems } = this.state;
        const { executorId } = this.props;
        const { _id, userName, executorPhone, cardNumber, name, error, transactionErrors, status } =
            item;
        const isCurrent = currentItems.includes(_id);
        const cardNumberIsError =
            (error === 'wallet-error' ||
                error === 'wallet-empty' ||
                transactionErrors?.includes('cardNumber') ||
                transactionErrors?.includes('cardError')) &&
            item.status !== 'completed';
        const userNameIsError = error === 'executor-not-found' && item.status !== 'completed';
        const userNameProps =
            userNameIsError || !item.executorId || executorId
                ? {}
                : error !== 'wallet-empty'
                  ? { pageName: 'manual-executors-inner-main', ids: { 3: item.executorId } }
                  : { pageName: 'manual-executors-inner-wallets', ids: { 3: item.executorId } };
        const UserNameTag = userNameIsError || !item.executorId || executorId ? 'div' : Link;
        const WalletsTag = item.executorId ? Link : 'div';
        const cardProps = item.executorId
            ? { pageName: 'manual-executors-inner-wallets', ids: { 3: item.executorId } }
            : {};
        const hasDublicates = item.hasDublicates && status === 'wait';
        const hasAmountDublicates = item.hasAmountDublicates && status === 'wait';

        return (
            <div
                className={`settingsUsersTable__row ${isCurrent ? '_active' : ''} ${
                    checkPaysErrors({ pay: item }) ? '_error' : ''
                } ${hasDublicates || hasAmountDublicates ? '_dublicates' : ''}`}
                key={_id}
                data-key={_id}
            >
                <div className="settingsUsersTable__rowInner _row">
                    <div className="settingsUsersTable__col _number">
                        {item.executorSign.isNotSign && (
                            <div
                                className="settingsUsersTable__executorNotSign _col"
                                onMouseEnter={(e) => {
                                    handlerSupportCursor({
                                        action: 'enter',
                                        content: `Односторонний акт`,
                                        e,
                                        data: { className: '_normalCase _center' },
                                    });
                                }}
                                onMouseLeave={(e) => {
                                    handlerSupportCursor({ action: 'leave', e });
                                }}
                            >
                                <i className="settingsUsersTable__executorNotSignIcon">
                                    <Icon name="noExecutorSign-act" />
                                </i>
                            </div>
                        )}
                        <div className="settingsUsersTable__checkbox">
                            <div className="settingsUsersTable__checkboxInner">
                                <Checkbox
                                    className="_square _blue"
                                    name={_id}
                                    value={isCurrent}
                                    handler={() => this.handlerCurrent(_id)}
                                />
                            </div>
                            <div className="settingsUsersTable__checkboxItem">{item.number}</div>
                        </div>
                        <div className="settingsUsersTable__executorOrg">
                            <ExecutorOrg name={item.executorOrganization} />
                        </div>
                    </div>
                    {this.state.user?.idOfCurrentCorporation === 'admin' && (
                        <div className="settingsUsersTable__col _corporationInfo">
                            <CorporationInfo {...(item.corporationInfo?.[0] || [])} />
                        </div>
                    )}
                    <div className="settingsUsersTable__col _name">
                        <div className="settingsUsersTable__text _active">
                            <div className="settingsUsersTable__textInner">{name || '–'}</div>
                        </div>
                    </div>
                    <div className="settingsUsersTable__col _userName">
                        <div
                            className={`settingsUsersTable__text _active ${
                                userNameIsError ? '_withIcon' : ''
                            }`}
                        >
                            <UserNameTag
                                className={`settingsUsersTable__textInner ${
                                    Object.keys(userNameProps).length ? '_link' : ''
                                } _name ${userNameIsError ? '_error' : ''}`}
                                {...userNameProps}
                            >
                                <span>
                                    {userName || executorPhone}

                                    {hasDublicates && (
                                        <div
                                            className="settingsUsersTable__textAlert"
                                            onMouseEnter={(e) => {
                                                handlerSupportCursor({
                                                    action: 'enter',
                                                    content: `Есть дубликаты по ФИО`,
                                                    e,
                                                    data: { className: '_normalCase _center' },
                                                });
                                            }}
                                            onMouseLeave={(e) => {
                                                handlerSupportCursor({ action: 'leave', e });
                                            }}
                                        >
                                            <i className="settingsUsersTable__textAlertIcon">
                                                <Icon name="alert" />
                                            </i>
                                        </div>
                                    )}
                                </span>
                            </UserNameTag>
                        </div>
                        <div className="settingsUsersTable__text _active _sub">
                            <WalletsTag
                                className={`settingsUsersTable__textInner ${
                                    cardNumberIsError ? '_error' : ''
                                } _link ${!cardNumberIsError && item.isUpdate ? '_update' : ''}`}
                                {...cardProps}
                            >
                                {cardNumber || '–'}
                            </WalletsTag>
                        </div>
                    </div>
                    {this.state.user?.idOfCurrentCorporation !== 'admin' && (
                        <div className="settingsUsersTable__col _period">
                            <div className="settingsUsersTable__text _active">
                                <div className="settingsUsersTable__textInner">
                                    {item.startPeriod && item.endPeriod ? (
                                        <>
                                            {getFormatedDate({
                                                date: new Date(item.startPeriod),
                                                isShortYear: true,
                                            })}{' '}
                                            <br />
                                            {getFormatedDate({
                                                date: new Date(item.endPeriod),
                                                isShortYear: true,
                                            })}
                                        </>
                                    ) : (
                                        <>–</>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="settingsUsersTable__col _amount">
                        <div className="settingsUsersTable__text _active _tax">
                            <span className="settingsUsersTable__textInner">
                                {getFormatPrice(+item.amount?.toFixed(2))} ₽
                            </span>
                            {item.beforeCommission && !executorId && (
                                <span
                                    className="settingsUsersTable__textInner _grey"
                                    onMouseEnter={(e) => {
                                        handlerSupportCursor({
                                            action: 'enter',
                                            content: `Сумма с комиссией<br/>Комиссия — ${+item.beforeCommission.toFixed(
                                                2,
                                            )} ₽`,
                                            e,
                                            data: { className: '_normalCase _center' },
                                        });
                                    }}
                                    onMouseLeave={(e) => {
                                        handlerSupportCursor({ action: 'leave', e });
                                    }}
                                >
                                    {getFormatPrice(
                                        +(+item.beforeCommission + +item.amount)?.toFixed(2),
                                    )}{' '}
                                    ₽
                                </span>
                            )}
                        </div>
                        {item.taxAmount && (
                            <div
                                className="settingsUsersTable__tax _row"
                                onMouseEnter={(e) => {
                                    handlerSupportCursor({
                                        action: 'enter',
                                        content: `С данной суммы удержано ${getFormatPrice(
                                            item.taxAmount,
                                        )} ₽<br/>в налоговую копилку`,
                                        e,
                                        data: { className: '_normalCase _center' },
                                    });
                                }}
                                onMouseLeave={(e) => {
                                    handlerSupportCursor({ action: 'leave', e });
                                }}
                            >
                                TAX
                                <i className="settingsUsersTable__taxIcon">
                                    <Icon name="app-settings-fns-tax" />
                                </i>
                            </div>
                        )}
                        {this.state.user?.idOfCurrentCorporation === 'admin' && (
                            <div className="settingsUsersTable__text _active">
                                <AnimateChangeUp
                                    className="settingsUsersTable__textInner"
                                    renderKey={item.date ?? 'null'}
                                >
                                    <>
                                        {item.date
                                            ? getFormatedDate({
                                                  date: new Date(item.date),
                                                  isShortYear: true,
                                              })
                                            : '–'}
                                    </>
                                </AnimateChangeUp>
                            </div>
                        )}
                        {hasAmountDublicates && (
                            <div
                                className="settingsUsersTable__alert _minSize"
                                onMouseEnter={(e) => {
                                    handlerSupportCursor({
                                        action: 'enter',
                                        content: `Есть дубликаты<br/>по сумме + назначению`,
                                        e,
                                        data: { className: '_normalCase _center' },
                                    });
                                }}
                                onMouseLeave={(e) => {
                                    handlerSupportCursor({ action: 'leave', e });
                                }}
                            >
                                <i className="settingsUsersTable__alertIcon">
                                    <Icon name="alert" />
                                </i>
                                Дубликат
                            </div>
                        )}
                    </div>
                    {this.state.user?.idOfCurrentCorporation !== 'admin' && (
                        <div className="settingsUsersTable__col _date">
                            <div className="settingsUsersTable__text _active">
                                <AnimateChangeUp
                                    className="settingsUsersTable__textInner"
                                    renderKey={item.date ?? 'null'}
                                >
                                    <>
                                        {item.date
                                            ? getFormatedDate({
                                                  date: new Date(item.date),
                                                  isShortYear: true,
                                              })
                                            : '–'}
                                    </>
                                </AnimateChangeUp>
                            </div>
                        </div>
                    )}
                    <div className="settingsUsersTable__col _sign">
                        {this.renderStatus({ key: 'sign', item })}
                    </div>
                    <div className="settingsUsersTable__col _status">
                        {this.renderStatus({ item })}
                        {this.getActionsItems({ pay: item }).length > 0 && (
                            <div
                                className="settingsUsersTable__more _fullRight _hide _click"
                                onClick={({ target }) =>
                                    this.handlerMore({
                                        target,
                                        _id,
                                    })
                                }
                            >
                                <Icon name="more-vertical" />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    setStartScroll() {
        const startScroll = localStorage.getItem('paysGroupsScroll');

        if (startScroll && !this.isSetStartScroll) {
            this.parent.current.querySelector('.settingsUsersTable__content').scrollTop =
                +startScroll;

            this.isSetStartScroll = true;
        }
    }

    updateItems(data) {
        return new Promise((resolve) => {
            this.setState({ currentItems: [] }, () => {
                super.updateItems(data).then(() => {
                    resolve();
                });
            });
        });
    }

    filterCallback({ filter, isChange }) {
        return new Promise((resolve) => {
            resolve();

            if (isChange) {
                this.setState({ filter }, () => {
                    this.updateItems();
                });
            }
        });
    }

    setPay({ isSign, isAll }) {
        const { currentItems = [] } = this.state;
        const { pay, isMain } = this.props;
        const filterQuery = this.getQueryForRequest().params;

        handlerPopup({
            name: 'paysPopup',
            isShow: true,
            groupId: isMain ? 'main' : pay._id,
            items: isAll ? ['all'] : currentItems,
            isSign,
            filterQuery,
            successCallback: () => {
                this.setState({ currentItems: [] });
            },
        });
    }

    getFilter() {
        const { executorId } = this.props;

        getFilter({ name: 'pays' }).then(({ blocks }) => {
            this.initFilter({
                blocks: blocks.filter((block) => !executorId || block.key !== 'searchForName'),
            });
        });
    }

    initFilter({ blocks = [] }) {
        const { initCallbackFilter } = this.props;

        this.handlerFilter.init({ blocks });

        initCallbackFilter(this.filterCallback);
    }

    deleteItems({ target }) {
        const { currentItems } = this.state;
        const { pay, getParent } = this.props;
        const parent = getParent();
        const filteredCurrentItems = currentItems;

        handlerWindow({
            parent,
            parentResize: parent,
            target: target.closest('.settingsUsersTable__headPaysButton'),
            action: 'show',
            name: 'promptDeletePays',
            className: '_prompt _center',
            uniqKey: `prompt-pays`,
            payId: pay._id,
            items: filteredCurrentItems,
            successDelete: () => {
                this.setState({ currentItems: [] });

                // this.updateItems();
            },
            centers: {
                left: 0.5,
                top: 1,
            },
        });
    }

    handlerLoaderList(isShowLoaderList) {
        this.setState({ isShowLoaderList });
    }

    getQueryForRequest() {
        const { levels, isMain, executorId } = this.props;
        const groupId = isMain ? 'main' : levels[3];
        const query = super.getQueryForRequest();

        query.params.push({ key: 'groupId', value: groupId });

        if (executorId) {
            query.params.push({ key: 'executorId', value: executorId });
        }

        if (isMain && !executorId && levels[3]) {
            query.params.push({ key: 'payId', value: levels[3] });
        }

        return query;
    }

    updatePay({ fields }) {
        this.setState((state) => {
            const newState = { ...state };
            const allPays = JSON.parse(JSON.stringify(newState.allPays || []));
            const items = JSON.parse(JSON.stringify(newState.items));
            const allItemIndex = allPays.findIndex((item) => item._id === fields._id);
            const itemIndex = items.findIndex((item) => item._id === fields._id);

            if (itemIndex !== -1) {
                Object.keys(fields).forEach((key) => {
                    items[itemIndex][key] = fields[key];
                });
            }

            if (allItemIndex !== -1) {
                Object.keys(fields).forEach((key) => {
                    allPays[allItemIndex][key] = fields[key];
                });
            }

            newState.items = items;
            newState.allPays = allPays;

            return newState;
        });
    }

    updatePays({ items: pays }) {
        this.setState((state) => {
            const newState = { ...state };
            const items = JSON.parse(JSON.stringify(newState.items));
            const allPays = JSON.parse(JSON.stringify(newState.allPays || []));

            pays.forEach((pay) => {
                const itemIndex = items.findIndex((item) => item._id === pay._id);
                const allItemIndex = allPays.findIndex((item) => item._id === pay._id);

                if (itemIndex !== -1) {
                    Object.keys(pay).forEach((key) => {
                        items[itemIndex][key] = pay[key];
                    });
                }

                if (allItemIndex !== -1) {
                    Object.keys(pay).forEach((key) => {
                        allPays[allItemIndex][key] = pay[key];
                    });
                }
            });

            newState.items = items;
            newState.allPays = allPays;

            return newState;
        });
    }

    getItems(isStart, isFilter) {
        const { setPayGroup } = this.props;
        const query = this.getQueryForRequest();

        if (isFilter) {
            query.params.push({ key: 'withGroup', value: true });
        }

        return new Promise((resolve) => {
            getPays(query).then(({ pays, isLimit, payGroup }) => {
                this.setItems(pays, false, isLimit).then(() => {
                    if (payGroup) {
                        setPayGroup({ payGroup });
                    }

                    if (isFilter) {
                        this.setState({ currentItems: [] });
                    }

                    resolve();
                });
            });
        });
    }

    handlerExport() {
        const { pay: payGroup } = this.props;
        const counter = payGroup?.counter;
        const query = this.getQueryForRequest();

        if (counter > 0) {
            handlerPopup({
                name: 'exportPopup',
                isShow: true,
                counter,
                filterQuery: query.params,
                type: 'pays',
            });
        }
    }

    handlerSocket({ detail }) {
        const { items } = this.state;
        const { pay, isMain } = this.props;
        const { name, data } = detail;

        if (name === 'pays') {
            const { fields } = data;

            if (fields) {
                if (fields.item) {
                    if (items?.find((item) => item._id === fields.item._id)) {
                        this.updatePay({ fields: fields.item });
                    }
                } else if (fields.items) {
                    this.updatePays(fields);
                } else if (
                    fields.deletedPays &&
                    (isMain || fields.deletedPays.find((item) => item.groupId === pay._id))
                ) {
                    this.updateItems();
                }
            }
        }
    }

    componentDidMount() {
        const { user } = this.props;

        this.setState({ user });

        super.componentDidMount();

        this.getFilter();

        this.getItems();

        this.handlerFilter = new HandlerFilter({
            context: this,
        });

        document.dispatchEvent(
            new CustomEvent('changeNavCounter', { detail: { name: 'pays-main' } }),
        );

        document.addEventListener('getSocketData', this.handlerSocket);
        document.addEventListener('newPay', this.updateItems);
    }

    componentWillUnmount() {
        document.removeEventListener('getSocketData', this.handlerSocket);
        document.removeEventListener('newPay', this.updateItems);
    }

    render() {
        const {
            currentItems,
            filter,
            filterUpdateKey,
            isShowLoaderList = false,
            isLimit,
            isDisabledScroll,
            isLoadingFilter = false,
            loadingKey,
            items = [],
            user,
        } = this.state;
        const { getParent, isMain, executorId } = this.props;
        const allWaitPays = items.filter(
            (pay) =>
                pay.status === 'wait' &&
                pay.isCorporationSign &&
                (user.idOfCurrentCorporation !== 'admin' ||
                    ['inactiveTax', 'tax'].includes(pay.type)),
        );
        const allSignedPays = items.filter(
            (pay) =>
                pay.status === 'wait' &&
                pay.isCorporationSign !== true &&
                (user.idOfCurrentCorporation !== 'admin' ||
                    ['inactiveTax', 'tax'].includes(pay.type)),
        );
        const allNotCompletedPays = items.filter(
            (pay) =>
                (pay.status === 'wait' || checkPaysErrors({ pay })) &&
                // !pay.wasStart &&
                (user.idOfCurrentCorporation !== 'admin' ||
                    ['tax', 'inactiveTax'].includes(pay.type)),
        );

        let { pay: payGroup } = this.props;

        const condForPay =
            allWaitPays.length > 0 &&
            currentItems.every((id) => allWaitPays.find(({ _id }) => _id === id));
        const condForSign =
            allSignedPays.length > 0 &&
            currentItems.every((id) => allSignedPays.find(({ _id }) => _id === id));
        const condForDelete =
            allNotCompletedPays.length > 0 &&
            currentItems.every((id) => allNotCompletedPays.find(({ _id }) => _id === id));

        const currentCounter = currentItems.length;
        const isInit = this.state.isReady && (!isMain || this.props.isInit);

        const savedPayGroup = { ...payGroup };
        let commission = savedPayGroup.commission || 0;

        if (currentItems.length) {
            const filteredItems = currentItems.map((id) => items.find((item) => item._id === id));

            commission = filteredItems.reduce((prev, cur) => prev + (cur.beforeCommission || 0), 0);

            payGroup = {
                amount: filteredItems.reduce((prev, cur) => prev + cur.amount, 0),
                commission,
                counter: currentItems.length,
                completedCounter: filteredItems.filter((item) => item.status === 'completed')
                    .length,
                errorCounter: filteredItems.filter((item) =>
                    ['error', 'failed'].includes(item.status),
                ).length,
                type: savedPayGroup.type,
            };
        }

        const amount = +((payGroup?.amount || 0) + commission).toFixed(2);

        let condForActions = true;

        if (
            user?.idOfCurrentCorporation === 'admin' &&
            !['inactiveTax', 'tax'].includes(payGroup?.type)
        ) {
            condForActions = false;
        }

        return (
            <div ref={this.parent} className={`manualContent _parentForEdits _pays`}>
                <div className="manualContent__inner">
                    <div className="manualContent__content">
                        <div
                            className={`settingsUsersTable _pays ${isInit ? '_init' : ''} ${
                                isLoadingFilter ? '_processFilter' : ''
                            } ${
                                this.state.user?.idOfCurrentCorporation === 'admin'
                                    ? '_adminCorporation'
                                    : ''
                            }`}
                        >
                            <div className="settingsUsersTable__inner">
                                <Animate
                                    className="settingsUsersTable__loader _loader _full"
                                    isShow={!isInit}
                                >
                                    <div className="settingsUsersTable__loaderItem _loaderItem">
                                        <Loader className="_main" />
                                    </div>
                                </Animate>
                                <Animate
                                    className="settingsUsersTable__loader _loader"
                                    isShow={isLoadingFilter}
                                >
                                    <div className="settingsUsersTable__loaderItem _loaderItem">
                                        <Loader className="_main" />
                                    </div>
                                </Animate>
                                <div className="settingsUsersTable__wrapper">
                                    <div className="settingsUsersTable__head _static">
                                        <div className="settingsUsersTable__headInner _row">
                                            <div className="settingsUsersTable__headPaysBlock _col">
                                                <AnimateChangeUp
                                                    className="settingsUsersTable__headPaysBlockInner _dynamic"
                                                    renderKey={
                                                        payGroup ? payGroup.counter : undefined
                                                    }
                                                    parentStyles={['width']}
                                                >
                                                    <>
                                                        <div className="settingsUsersTable__headPaysTitle">
                                                            <>
                                                                <b>
                                                                    {getFormatPrice(
                                                                        payGroup?.counter,
                                                                    )}{' '}
                                                                    {getEndText(payGroup?.counter, [
                                                                        'акт',
                                                                        'акта',
                                                                        'актов',
                                                                    ])}
                                                                </b>{' '}
                                                                {getFormatPrice(
                                                                    +amount?.toFixed(2),
                                                                )}{' '}
                                                                ₽
                                                                {commission ? (
                                                                    <>
                                                                        , ком.{' '}
                                                                        {getFormatPrice(
                                                                            +commission?.toFixed(2),
                                                                        )}{' '}
                                                                        ₽
                                                                    </>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </>
                                                        </div>
                                                        <div className="settingsUsersTable__headPaysDescription _row">
                                                            <div className="settingsUsersTable__headPaysDescriptionItem _completed">
                                                                Оплачено:{' '}
                                                                {getFormatPrice(
                                                                    payGroup?.completedCounter,
                                                                )}{' '}
                                                                из{' '}
                                                                {getFormatPrice(payGroup?.counter)}
                                                            </div>
                                                            <div className="settingsUsersTable__headPaysDescriptionItem _error">
                                                                Ошибки:{' '}
                                                                {getFormatPrice(
                                                                    payGroup?.errorCounter,
                                                                )}
                                                            </div>
                                                        </div>
                                                    </>
                                                </AnimateChangeUp>
                                            </div>
                                            <div className="settingsUsersTable__headPaysBlock _col _actions">
                                                {condForActions && (
                                                    <>
                                                        {this.getPermissions({
                                                            key: 'pays',
                                                            items: [
                                                                { key: 'acts', rules: ['sign'] },
                                                            ],
                                                        }) && (
                                                            <>
                                                                <div className="settingsUsersTable__headPaysButton">
                                                                    <Button
                                                                        className="_crm _mainNotBorder"
                                                                        isDisabled={
                                                                            currentCounter === 0 ||
                                                                            !condForSign
                                                                        }
                                                                        onClick={() =>
                                                                            this.setPay({
                                                                                isSign: true,
                                                                            })
                                                                        }
                                                                        showLoader={
                                                                            loadingKey ===
                                                                            'setSignPays'
                                                                        }
                                                                    >
                                                                        Подписать
                                                                    </Button>
                                                                </div>
                                                                <div className="settingsUsersTable__headPaysButton">
                                                                    <Button
                                                                        className="_crm _mainNotBorder"
                                                                        isDisabled={
                                                                            currentCounter === 0 ||
                                                                            !condForPay
                                                                        }
                                                                        onClick={() =>
                                                                            this.setPay({})
                                                                        }
                                                                        showLoader={
                                                                            loadingKey === 'setPays'
                                                                        }
                                                                    >
                                                                        Оплатить
                                                                    </Button>
                                                                </div>
                                                            </>
                                                        )}

                                                        {this.getPermissions({
                                                            key: 'pays',
                                                            items: [
                                                                {
                                                                    key: 'acts',
                                                                    rules: ['delete'],
                                                                },
                                                            ],
                                                        }) && (
                                                            <div className="settingsUsersTable__headPaysButton">
                                                                <Button
                                                                    className="_crm _cancel"
                                                                    isDisabled={
                                                                        currentCounter === 0 ||
                                                                        !condForDelete
                                                                    }
                                                                    onClick={this.deleteItems}
                                                                >
                                                                    Удалить
                                                                </Button>
                                                            </div>
                                                        )}

                                                        {savedPayGroup?.waitedCounter > 0 &&
                                                            this.getPermissions({
                                                                key: 'pays',
                                                                items: [
                                                                    {
                                                                        key: 'acts',
                                                                        rules: ['sign'],
                                                                    },
                                                                ],
                                                            }) && (
                                                                <div className="settingsUsersTable__headPaysButton">
                                                                    <More
                                                                        isActions={true}
                                                                        pages={[
                                                                            {
                                                                                name: 'payAll',
                                                                                contentOfLink: `Оплатить все (${
                                                                                    savedPayGroup?.waitedCounter ||
                                                                                    0
                                                                                })`,
                                                                                callback: () => {
                                                                                    this.setPay({
                                                                                        isAll: true,
                                                                                    });
                                                                                },
                                                                            },
                                                                        ]}
                                                                    />
                                                                </div>
                                                            )}
                                                    </>
                                                )}
                                                {(!isMain || executorId) && (
                                                    <div
                                                        className="widget__headAction settingsUsersTable__headPaysButton _grey"
                                                        onClick={this.handlerExport}
                                                    >
                                                        <div className="widget__headActionInner _col _click">
                                                            <i className="widget__headActionIcon">
                                                                <Icon name="widget-export" />
                                                            </i>
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="settingsUsersTable__headPaysButton">
                                                    <WidgetFilter
                                                        name="pays"
                                                        filter={filter}
                                                        handlerFilter={this.handlerFilter}
                                                        getParent={getParent}
                                                        windowName="paysFilter"
                                                        className="_grey"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="settingsUsersTable__contentWrapper">
                                        <Animate
                                            className="settingsUsersTable__empty"
                                            isShow={items.length === 0 && !isLoadingFilter}
                                        >
                                            <div className="empty _col _notBack _block">
                                                <div className="empty__inner">
                                                    <div className="empty__title">
                                                        На данный момент выплат нет
                                                    </div>
                                                    <p className="empty__content">
                                                        Они будут отображаться здесь в виде карточек
                                                    </p>
                                                </div>
                                            </div>
                                        </Animate>
                                        <div
                                            className="settingsUsersTable__content JSTransVertical"
                                            onScroll={({ target }) => {
                                                localStorage.setItem(
                                                    'paysGroupsScroll',
                                                    target.scrollTop,
                                                );
                                            }}
                                        >
                                            <div className="settingsUsersTable__row _head">
                                                <div className="settingsUsersTable__col _number">
                                                    №
                                                </div>
                                                {this.state.user?.idOfCurrentCorporation ===
                                                    'admin' && (
                                                    <div className="settingsUsersTable__col _corporationInfo">
                                                        Корпорация
                                                    </div>
                                                )}
                                                <div className="settingsUsersTable__col _name">
                                                    Назначение
                                                </div>
                                                <div className="settingsUsersTable__col _userName">
                                                    Исполнитель
                                                </div>
                                                {this.state.user?.idOfCurrentCorporation ===
                                                'admin' ? (
                                                    <>
                                                        <div className="settingsUsersTable__col _amount">
                                                            Сумма и дата
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="settingsUsersTable__col _period">
                                                            Период
                                                        </div>
                                                        <div className="settingsUsersTable__col _amount">
                                                            Сумма
                                                        </div>
                                                        <div className="settingsUsersTable__col _date">
                                                            Дата акта
                                                        </div>
                                                    </>
                                                )}
                                                <div className="settingsUsersTable__col _sign">
                                                    Подпись
                                                </div>
                                                <div className="settingsUsersTable__col _status">
                                                    Платёж
                                                </div>
                                            </div>
                                            <div className="settingsUsersTable__contentInner">
                                                <div
                                                    className="settingsUsersTable__rows _col"
                                                    key={filterUpdateKey}
                                                >
                                                    {isInit && (
                                                        <ListScroll
                                                            getParent={() =>
                                                                this.parent.current?.querySelector(
                                                                    '.settingsUsersTable__content',
                                                                )
                                                            }
                                                            callback={this.getMoreItems}
                                                            startCounter={this.stepCounter}
                                                            stepCounter={this.stepCounter}
                                                            maxCounter={Infinity}
                                                            lengthCurrent={items.length}
                                                            keyUpdate={items.length}
                                                            isDisabledScroll={
                                                                isDisabledScroll || isLoadingFilter
                                                            }
                                                            isLimit={isLimit}
                                                            handlerLoaderList={
                                                                this.handlerLoaderList
                                                            }
                                                        >
                                                            {items.map((item, key) =>
                                                                this.renderRow({ item, key }),
                                                            )}
                                                        </ListScroll>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <Animate
                                            className="settingsUsersTable__listLoader _loaderScroll _white"
                                            isShow={isShowLoaderList}
                                        >
                                            <div className="settingsUsersTable__listLoaderItem _loaderItem">
                                                <Loader className="_main" />
                                            </div>
                                        </Animate>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        levels: state.levels,
        user: state.user,
        windows: state.windows,
    };
}

export default connect(mapStateToProps)(PayGroupsInnerMain);

PayGroupsInnerMain.propTypes = {
    pay: PropTypes.object,
    getParent: PropTypes.func,
    initCallbackFilter: PropTypes.func,
    user: PropTypes.object,
    windows: PropTypes.object,
    isMain: PropTypes.bool,
    isInit: PropTypes.bool,
    checkRights: PropTypes.func,
    executorId: PropTypes.string,
    setPayGroup: PropTypes.func,
};
