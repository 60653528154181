import axios from 'axios';
import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import changePage from '../../../../functions/changePage';
import copyInBuffer from '../../../../functions/copyInBuffer';
import getCurrentCorporation from '../../../../functions/crm/getCurrentCorporation';
import getHeaders from '../../../../functions/getHeaders';
import getPageLink from '../../../../functions/getPageLink';
import getUserInfo from '../../../../functions/getUserInfo';
import handlerLoading from '../../../../functions/handlerLoading';
import setNotification from '../../../../functions/setNotification';

import Animate from '../../../Animate.jsx';
import Icon from '../../../Icon.jsx';
import Link from '../../../Link.jsx';
import Loader from '../../../Loader.jsx';
import Field from '../card/Field.jsx';
import Head from '../card/Head.jsx';
import MainPreview from '../card/executor/MainPreview.jsx';

class ManualExecutorsMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.checkFns = this.checkFns.bind(this);
    }

    getDescriptionInfo() {
        const { executor, isNew } = this.props;

        if (executor) {
            if (isNew) {
                return {
                    description: 'test',
                };
            }

            return {
                description: executor.typeText,
            };
        }

        return {
            description: '–',
        };
    }

    fields = [
        { name: 'type', isReadOnly: !this.props.isNew },
        { name: 'phone' },
        { name: 'organization', isReadOnly: !this.props.isNew },
        { name: 'town' },
        { name: 'telegram' },
        { name: 'rate' },
        { name: 'inn' },
        { name: 'inactive' },
    ];

    filterField(field) {
        const { user } = this.state;

        if (user?.idOfCurrentCorporation === 'admin' && field.name === 'inactive') {
            return false;
        }

        return true;
    }

    checkFns() {
        const { executor, setExecutor } = this.props;

        handlerLoading.call(this, 'checkFns').then(() => {
            axios
                .patch(
                    `${process.env.REACT_APP_API}/executor`,
                    {
                        action: 'check-fns',
                        id: executor._id,
                    },
                    { headers: getHeaders() },
                )
                .then((res) => {
                    handlerLoading.call(this, null);

                    const { success, data } = res.data;

                    if (success) {
                        const { firstName, secondName, thirdName, phone, isChange } = data;

                        if (isChange) {
                            const updatedExecutor = JSON.parse(JSON.stringify(executor));

                            updatedExecutor.firstName = firstName;
                            updatedExecutor.secondName = secondName;
                            updatedExecutor.thirdName = thirdName;
                            updatedExecutor.phone = phone;

                            setExecutor(updatedExecutor);

                            setNotification({ notification: 'success-change-info' });
                        } else {
                            setNotification({ notification: 'fns-executor-info-not-change' });
                        }
                    } else {
                        const { message } = data;

                        if (message === 'Account not adding for company') {
                            setNotification({ notification: 'fns-account-not-add' });
                        } else if (message === 'Executor already create') {
                            setNotification({ notification: 'fns-executor-phone-use' });
                        }
                    }
                });
        });
    }

    componentDidMount() {
        const { user } = this.props;

        this.setState({ user });
    }

    render() {
        const { loadingKey, user } = this.state;
        const {
            type,
            title,
            executor = {},
            changeExecutor,
            checkEditmode = () => false,
            errors = [],
            isNew,
        } = this.props;
        const { description } = this.getDescriptionInfo();
        const executorName = getUserInfo({ user: executor, type: 'name' });

        return (
            <div className="manualCard _executorMain">
                <div className="manualCard__head _row">
                    <Head title={title} description={description}>
                        <div className="manualCard__headCopy _row">
                            <div className="manualCard__headCopyInner">{executorName}</div>
                            <div
                                className="manualCard__headCopyIcon _col _click"
                                onClick={() => {
                                    copyInBuffer(executorName);
                                }}
                            >
                                <i className="manualCard__headCopyIconInner">
                                    <Icon name="copy" />
                                </i>
                            </div>
                        </div>
                    </Head>
                    {type === 'preview' ? (
                        <>
                            <div className="manualCard__headInfo">
                                <Link
                                    pageName="manual-executors-inner"
                                    className="manualCard__headLink _row"
                                    ids={{ 3: executor._id }}
                                >
                                    На страницу
                                    <i className="manualCard__headLinkIcon">
                                        <Icon name="arrow-next" />
                                    </i>
                                </Link>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="manualCard__headInfo">
                                {getCurrentCorporation({ user, isInfo: false })?.id === 'admin' &&
                                    executor.organization === 'SMZ' &&
                                    !isNew && (
                                        <div
                                            className="manualCard__headLink _row _click"
                                            onClick={this.checkFns}
                                        >
                                            <Animate
                                                className="manualCard__headLinkLoader _loader"
                                                isShow={loadingKey === 'checkFns'}
                                            >
                                                <i className="manualCard__headLinkLoaderItem _loaderItem">
                                                    <Loader className="_main" />
                                                </i>
                                            </Animate>
                                            Обновить с ФНС
                                        </div>
                                    )}
                            </div>
                        </>
                    )}
                </div>
                <div className="manualCard__content">
                    <div className="manualCard__main _row">
                        <div className="manualCard__mainItem _preview">
                            <MainPreview
                                executor={executor}
                                handler={changeExecutor}
                                isDisabled={!checkEditmode()}
                                errors={errors}
                                isNew={isNew}
                            />
                        </div>
                        <div className="manualCard__mainItem _fields">
                            <div className="manualCard__fields _row">
                                {this.fields.filter(this.filterField.bind(this)).map((field) => {
                                    const { name } = field;
                                    let { isReadOnly } = field;
                                    const { [name]: value } = executor;
                                    const otherInfo = {};
                                    const otherProps = {};

                                    let notItems;

                                    if (name === 'type' || name === 'organization') {
                                        isReadOnly = !this.props.isNew;
                                    }

                                    if (checkEditmode() && name === 'type' && !value) {
                                        isReadOnly = false;
                                    }

                                    if (name === 'status') {
                                        notItems = [
                                            { key: 'invite' },
                                            { key: 'check-invite' },
                                            { key: 'invite-process' },
                                            { key: 'app-join' },
                                            { key: 'app-invite' },
                                        ];
                                    }

                                    if (name === 'phone' && executor.appChatId) {
                                        otherProps.action = 'appChat';
                                        otherProps.handlerAction = () =>
                                            new Promise((resolve) => {
                                                changePage({
                                                    href: getPageLink({
                                                        name: 'chat-inner',
                                                        ids: { 3: executor.appChatId },
                                                    }),
                                                });

                                                resolve();
                                            });
                                    }

                                    if (name === 'telegram' && executor.telegramChatId) {
                                        otherProps.action = 'telegramChat';
                                        otherProps.handlerAction = () =>
                                            new Promise((resolve) => {
                                                changePage({
                                                    href: getPageLink({
                                                        name: 'chat-inner',
                                                        ids: { 3: executor.telegramChatId },
                                                    }),
                                                });

                                                resolve();
                                            });
                                    }

                                    if (
                                        executor.verification.status === 'active' &&
                                        user?.idOfCurrentCorporation !== 'admin'
                                    ) {
                                        isReadOnly = name !== 'inactive';
                                    }

                                    return (
                                        <div className={`manualCard__field _${name}`} key={name}>
                                            <Field
                                                value={value}
                                                type="executor"
                                                name={name}
                                                handler={changeExecutor}
                                                group="executorMain"
                                                isDisabled={!checkEditmode()}
                                                isReadOnly={isReadOnly}
                                                isError={errors.indexOf(name) !== -1}
                                                notItems={notItems}
                                                otherInfo={otherInfo}
                                                {...otherProps}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(ManualExecutorsMain);

ManualExecutorsMain.propTypes = {
    type: PropTypes.string,
    executor: PropTypes.object,
    title: PropTypes.string,
    changeExecutor: PropTypes.func,
    checkEditmode: PropTypes.func,
    isNew: PropTypes.bool,
    errors: PropTypes.array,
    user: PropTypes.object,
    setExecutor: PropTypes.func,
};
