import React, { MouseEvent } from 'react';
import { connect } from 'react-redux';

import { ListenerT, StoreT } from '@global/types.ts';

import init from './methods/init.ts';
import inputHandler from './methods/inputHandler.ts';
import outClickHandler from './methods/outClickHandler.ts';
import sendForm from './methods/sendForm.ts';

import CallPopupI from './types.ts';

import renderContent from './renders/renderContent.tsx';
import renderField from './renders/renderField.tsx';
import renderFoot from './renders/renderFoot.tsx';
import renderHead from './renders/renderHead.tsx';
import fields from './static/fields.ts';

class CallPopup
    extends React.Component<CallPopupI['props'], CallPopupI['state']>
    implements CallPopupI
{
    parent: CallPopupI['parent'];

    constructor(props: CallPopupI['props']) {
        super(props);
        this.state = {
            fields: {},
        };

        this.outClickHandler = this.outClickHandler.bind(this);

        this.parent = React.createRef();
    }

    fields = fields;

    init = init;
    outClickHandler = outClickHandler;
    inputHandler = inputHandler;
    sendForm = sendForm;

    renderHead = renderHead;
    renderContent = renderContent;
    renderFoot = renderFoot;

    renderField = renderField;

    componentDidMount(): void {
        this.init();
    }

    componentWillUnmount(): void {
        (document.removeEventListener as ListenerT<MouseEvent>)('click', this.outClickHandler);
    }

    render() {
        const { heightWindow } = this.props;

        return (
            <div
                ref={this.parent}
                className="publicCallPopup _COL _CENTER _NOSCROLL"
                style={{ height: `${heightWindow}px` }}
            >
                <div className="publicCallPopup__inner _COL">
                    {this.renderHead()}
                    {this.renderContent()}
                    {this.renderFoot()}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: StoreT) {
    return {
        heightWindow: state.heightWindow,
    };
}

export default connect(mapStateToProps)(CallPopup);
