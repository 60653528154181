import axios from 'axios';
import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import getUpdateFormData from '@functions/getUpdateFormData.js';

import Animate from '../../../components/Animate.jsx';
import AnimateChangeUp from '../../../components/AnimateChangeUp.jsx';
import BorderDashed from '../../../components/BorderDashed.jsx';
import Button from '../../../components/Button.jsx';
import Edit from '../../../components/Edit.jsx';
import Field from '../../../components/Field.jsx';
import Icon from '../../../components/Icon.jsx';
import ImageLazy from '../../../components/ImageLazy.jsx';
import Loader from '../../../components/Loader.jsx';
import Head from '../../../components/crm/manual/card/Head.jsx';
import UserMain from '../../../components/crm/profile/Main.jsx';
import Main from '../../../components/crm/settings/docs/Main.jsx';
import Passport from '../../../components/crm/settings/docs/Passport.jsx';

import checkImageFile from '../../../functions/checkImageFile';
import getFormatedDate from '../../../functions/getFormatedDate';
import getHeaders from '../../../functions/getHeaders';
import handlerLoading from '../../../functions/handlerLoading';
import handlerPopup from '../../../functions/handlerPopup';
import setSpacesInText from '../../../functions/setSpacesInText';

import handlerCorporationVerification from '../../../requests/handlerCorporationVerification';

class SettingsDocs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.changeRejectComment = this.changeRejectComment.bind(this);
        this.setEditName = this.setEditName.bind(this);
        this.changeHolder = this.changeHolder.bind(this);
    }

    folder = 'corporations-settings-docs';

    getDocFileSrc({ doc }) {
        return doc.file?.path
            ? `${process.env.REACT_APP_STATIC}/${this.folder}/${doc.file?.path}`
            : null;
    }

    openGalery({ id }) {
        const uploadedDocs = this.getUploadedDocs();
        const key = uploadedDocs.findIndex((item) => item._id === id);

        handlerPopup({
            action: 'show',
            name: 'popupGalery',
            modelName: 'corporationDocs',
            images: uploadedDocs.map((innerDoc) => ({
                type: innerDoc.file.type,
                path: this.getDocFileSrc({
                    doc: innerDoc,
                }),
                folder: this.folder,
                src: innerDoc.file?.path,
                notRotate: true,
            })),
            title: 'Загруженные документы',
            currentIndex: key,
        });
    }

    renderDoc({ doc }) {
        const { loadingKey } = this.state;
        const { corporation } = this.props;
        const user = this.getUser();

        const isLoading = loadingKey === doc._id;
        const fileId = `corpDoc-${doc._id}`;
        const filePath = this.getDocFileSrc({ doc });
        const isDisabled = !(
            corporation.verification.status !== 'active' || user.idOfCurrentCorporation === 'admin'
        );
        const deleteLoading = loadingKey === `delete-${doc._id}`;

        return (
            <div className="settingsDocs__doc" key={doc._id}>
                <label className="settingsDocs__docPreview _click _col">
                    <input
                        type="file"
                        className="settingsDocs__docInput"
                        onChange={this.uploadFile.bind(this, doc._id)}
                        disabled={!!loadingKey || isDisabled}
                        id={fileId}
                    />
                    <div className="settingsDocs__docPreviewBorder">
                        <BorderDashed rx={20} ry={20} />
                    </div>
                    <Animate className="settingsDocs__docPreviewLoader _loader" isShow={isLoading}>
                        <div className="settingsDocs__docPreviewLoaderItem _loaderItem">
                            <Loader className="_main" />
                        </div>
                    </Animate>
                    <div
                        className={`settingsDocs__docPreviewInner _col ${
                            isLoading ? '_loading' : ''
                        }`}
                    >
                        <i className="settingsDocs__docPreviewIcon">
                            <Icon name="add" />
                        </i>
                        <div className="settingsDocs__docPreviewName">{doc.name}</div>
                    </div>
                </label>
                <AnimateChangeUp
                    className="settingsDocs__docFile _notTransform"
                    renderKey={doc.file?.path}
                    isNotParams={true}
                >
                    {doc.file?.path ? (
                        <>
                            <div
                                className={`settingsDocs__docFileInner ${
                                    isDisabled ? '_click' : ''
                                }`}
                                onClick={() => {
                                    if (isDisabled) {
                                        this.openGalery({ id: doc._id });
                                    }
                                }}
                            >
                                {checkImageFile(doc.file) ? (
                                    <>
                                        <ImageLazy
                                            className="settingsDocs__docFileImage"
                                            src={filePath}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <div className="settingsDocs__docFilePreview _col">
                                            <i className="settingsDocs__docFilePreviewIcon">
                                                <Icon name="file-type-doc" />
                                            </i>
                                            <p className="settingsDocs__docFilePreviewDescription">
                                                Предпросмотр невозможен
                                            </p>
                                        </div>
                                    </>
                                )}

                                <div className="settingsDocs__docFileInfo _col">
                                    <div className="settingsDocs__docFileInfoName">{doc.name}</div>
                                    <div className="settingsDocs__docFileInfoDate">
                                        {getFormatedDate({ date: new Date(doc.date) })}
                                    </div>
                                    <div className="settingsDocs__docFileInfoActions _row">
                                        {!isDisabled && (
                                            <>
                                                <label
                                                    className="settingsDocs__docFileInfoAction _click _col"
                                                    htmlFor={fileId}
                                                >
                                                    <i className="settingsDocs__docFileInfoActionIcon">
                                                        <Icon name="corporation-settings-upload" />
                                                    </i>
                                                </label>
                                                <div
                                                    className={`settingsDocs__docFileInfoAction _click _col ${
                                                        deleteLoading ? '_loading' : ''
                                                    }`}
                                                    onClick={this.deleteFile.bind(this, doc._id)}
                                                >
                                                    <Animate
                                                        className="settingsDocs__docFileInfoActionLoader _loader"
                                                        isShow={deleteLoading}
                                                    >
                                                        <div className="settingsDocs__docFileInfoActionLoaderItem _loaderItem">
                                                            <Loader />
                                                        </div>
                                                    </Animate>
                                                    <i className="settingsDocs__docFileInfoActionIcon">
                                                        <Icon name="corporation-settings-delete" />
                                                    </i>
                                                </div>
                                                <div
                                                    className="settingsDocs__docFileInfoAction _click _col"
                                                    onClick={() => {
                                                        this.openGalery({ id: doc._id });
                                                    }}
                                                >
                                                    <i className="settingsDocs__docFileInfoActionIcon">
                                                        <Icon name="corporation-settings-search" />
                                                    </i>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                </AnimateChangeUp>
            </div>
        );
    }

    handlerDoc({ formData }) {
        return new Promise((resolve, reject) => {
            axios
                .patch(`${process.env.REACT_APP_API}/corporation`, getUpdateFormData(formData), {
                    headers: getHeaders(),
                })
                .then((res) => {
                    const { success, data } = res.data;

                    if (success) {
                        resolve(data);
                    } else {
                        reject();
                    }
                }, reject);
        });
    }

    deleteFile(docId) {
        const { corporation, updateCorporation } = this.props;

        handlerLoading.call(this, `delete-${docId}`).then(() => {
            const formData = new FormData();

            formData.set('id', corporation._id);
            formData.set('action', 'handler-doc');
            formData.set('docId', docId);
            formData.set('docAction', 'delete');

            this.handlerDoc({ formData }).then(
                () => {
                    const docs = [...corporation.docs];
                    const docIndex = docs.findIndex((item) => item._id === docId);

                    docs[docIndex].file = null;
                    docs[docIndex].date = null;

                    updateCorporation({ fields: { docs }, isSave: true });

                    handlerLoading.call(this, null);
                },
                () => {
                    handlerLoading.call(this, null);
                },
            );
        });
    }

    uploadFile(docId, { target }) {
        const { corporation, updateCorporation } = this.props;
        const [file] = target.files;

        handlerLoading.call(this, docId).then(() => {
            const formData = new FormData();

            formData.set('id', corporation._id);
            formData.set('file', file);
            formData.set('action', 'handler-doc');
            formData.set('docId', docId);

            this.handlerDoc({ formData }).then(
                ({ doc }) => {
                    const docs = [...corporation.docs];
                    const docIndex = docs.findIndex((item) => item._id === docId);

                    docs[docIndex].file = doc.file;
                    docs[docIndex].date = doc.date;

                    updateCorporation({ fields: { docs }, isSave: true });

                    handlerLoading.call(this, null);
                },
                () => {
                    handlerLoading.call(this, null);
                },
            );
        });
    }

    getUploadedDocs() {
        const { corporation } = this.props;

        return corporation.docs.filter((doc) => !!doc.file?.path);
    }

    handlerVerification(status) {
        const { rejectComment } = this.state;
        const { corporation, updateCorporation } = this.props;

        handlerLoading.call(this, status).then(() => {
            handlerCorporationVerification({
                id: corporation._id,
                status,
                comment: rejectComment,
            }).then(
                (verificationData) => {
                    const verification = { ...corporation.verification, ...verificationData };

                    if (status === 'active') {
                        verification.comment = null;
                    }

                    updateCorporation({ fields: { verification } });

                    handlerLoading.call(this, null, {
                        verificationRejectShow: false,
                        ...(status === 'active' ? { rejectComment: null } : {}),
                    });
                },
                () => {
                    handlerLoading.call(this, null);
                },
            );
        });
    }

    changeRejectComment({ action, value }) {
        return new Promise((resolve) => {
            if (action !== 'change') {
                resolve();
            } else {
                this.setState({ rejectComment: value });
            }
        });
    }

    setEditName({ editName }) {
        this.setState({ editName });
    }

    getUser() {
        return this.state.user || this.props.user;
    }

    changeHolder({ block, action, name, value }) {
        return new Promise((resolve) => {
            if (action === 'change' || !action) {
                this.setState((state) => {
                    const newState = { ...state };
                    const holder = JSON.parse(JSON.stringify(newState.holder));
                    const target = block ? holder[block] : holder;

                    target[name] = value;

                    newState.holder = holder;

                    return newState;
                }, resolve);
            }
        });
    }

    componentDidMount() {
        const { corporation } = this.props;
        const holder = corporation.holder;

        this.setState({
            user: this.props.user,
            rejectComment: corporation.verification.comment,
            holder,
            holderSave: JSON.parse(JSON.stringify(holder)),
        });
    }

    render() {
        const {
            loadingKey,
            rejectComment = '',
            verificationRejectShow = false,
            editName,
            holder,
            holderSave,
            editLoadingKey,
        } = this.state;
        const { corporation, inner } = this.props;
        const status = corporation.verification.status;
        const uploadedDocs = this.getUploadedDocs();
        const docsDescription = `${uploadedDocs.length}/${corporation.docs.length}`;
        const user = this.getUser();
        const activeNotAdmin = status === 'active' && user?.idOfCurrentCorporation !== 'admin';
        const hasVerificationCorporation = !!user.corporationsInfo.find(
            (item) => item.verificationStatus === 'active' && item.role === 'holder',
        );

        return (
            <div
                ref={this.parent}
                className={`settingsDocs _parentForEdits ${editName ? '_edit' : ''}`}
            >
                <div className="settingsDocs__inner">
                    <div className="settingsDocs__cards _row">
                        <div className="settingsDocs__card _editBack _verification">
                            <div className="manualCard _hidden">
                                {!activeNotAdmin && (
                                    <div className="manualCard__head _row">
                                        <Head title="Верификация" description=" " />
                                    </div>
                                )}
                                <div
                                    className={`manualCard__content ${
                                        activeNotAdmin ? '_fullHeight' : ''
                                    }`}
                                >
                                    <div
                                        className={`settingsDocs__verification _col _${status} ${
                                            verificationRejectShow ? '_showReject' : ''
                                        }`}
                                    >
                                        <Animate
                                            className="settingsDocs__verificationReject _col"
                                            isShow={verificationRejectShow}
                                        >
                                            <div className="settingsDocs__verificationRejectArea">
                                                <Field
                                                    value={rejectComment}
                                                    type="verification"
                                                    name="comments"
                                                    keyName="comments"
                                                    className="_grey"
                                                    callback={this.changeRejectComment}
                                                />
                                            </div>
                                            <div className="settingsDocs__verificationRejectButtons _row">
                                                <div className="settingsDocs__verificationRejectButton">
                                                    <Button
                                                        className="_mediumSize"
                                                        onClick={this.handlerVerification.bind(
                                                            this,
                                                            'reject',
                                                        )}
                                                        showLoader={loadingKey === 'reject'}
                                                    >
                                                        Отправить
                                                    </Button>
                                                </div>
                                                <div
                                                    className="settingsDocs__verificationRejectButton _col _click _close"
                                                    onClick={() => {
                                                        this.setState({
                                                            verificationRejectShow: false,
                                                        });
                                                    }}
                                                >
                                                    <i className="settingsDocs__verificationRejectButtonIcon">
                                                        <Icon name="close" />
                                                    </i>
                                                </div>
                                            </div>
                                        </Animate>
                                        <div className="settingsDocs__verificationInner _col">
                                            {activeNotAdmin ? (
                                                <>
                                                    <div className="settingsDocs__verificationResult _col">
                                                        <i className="settingsDocs__verificationIcon">
                                                            <Icon name="corporation-verify" />
                                                        </i>
                                                        <div className="settingsDocs__verificationStatus">
                                                            Верификация
                                                            <br />
                                                            пройдена
                                                        </div>
                                                        <div className="settingsDocs__verificationDate">
                                                            {getFormatedDate({
                                                                date: new Date(
                                                                    corporation.verification.date,
                                                                ),
                                                            })}
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className="settingsDocs__verificationHead">
                                                        <i className="settingsDocs__verificationIcon">
                                                            <Icon name="corporation-verify" />
                                                        </i>
                                                        <div className="settingsDocs__verificationTitle">
                                                            Статус
                                                        </div>
                                                        <AnimateChangeUp
                                                            className="settingsDocs__verificationStatus"
                                                            renderKey={status}
                                                        >
                                                            {corporation?.verification.statusText}
                                                        </AnimateChangeUp>
                                                    </div>
                                                    <div className="settingsDocs__verificationContent">
                                                        {inner ? (
                                                            <>
                                                                <Animate
                                                                    className="settingsDocs__verificationButton _set"
                                                                    isShow={status !== 'active'}
                                                                    onClick={this.handlerVerification.bind(
                                                                        this,
                                                                        'active',
                                                                    )}
                                                                    showLoader={
                                                                        loadingKey === 'active'
                                                                    }
                                                                >
                                                                    <Button className="_mediumSize">
                                                                        Верифицировать
                                                                    </Button>
                                                                </Animate>
                                                                <div className="settingsDocs__verificationButton">
                                                                    <Button
                                                                        className="_mediumSize _alertLight"
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                verificationRejectShow:
                                                                                    true,
                                                                            });
                                                                        }}
                                                                    >
                                                                        На доработку
                                                                    </Button>
                                                                </div>
                                                            </>
                                                        ) : rejectComment ? (
                                                            <div className="settingsDocs__verificationComment">
                                                                <div
                                                                    className="settingsDocs__verificationCommentInner"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: setSpacesInText(
                                                                            rejectComment,
                                                                        ),
                                                                    }}
                                                                ></div>
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="settingsDocs__card _editBack _docs">
                            <div className="manualCard">
                                <div className="manualCard__head _row">
                                    <Head
                                        title="Загруженные документы"
                                        description={docsDescription}
                                    />
                                </div>
                                <div className="manualCard__content _full">
                                    <div className="settingsDocs__docsWrapper">
                                        <div className="settingsDocs__docs">
                                            {corporation.docs.map((doc, key) =>
                                                this.renderDoc({ doc, key }),
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`settingsDocs__card _parentForEdit _editBack ${
                                editName === 'main' ? '_current' : ''
                            }`}
                        >
                            {(corporation.verification.status !== 'active' ||
                                user.idOfCurrentCorporation === 'admin') && (
                                <Edit
                                    name="main"
                                    className="manualContent__cardEdit"
                                    editName={editName}
                                    handlerEditmode={this.mainHandlerEditmode}
                                    isLoader={editLoadingKey === 'main'}
                                />
                            )}
                            <Main
                                corporation={corporation}
                                setEditName={this.setEditName}
                                setHandlerEditmode={(fn) => {
                                    this.mainHandlerEditmode = fn;
                                }}
                                setLoadingKey={(key) => {
                                    this.setState({ editLoadingKey: key });
                                }}
                            />
                        </div>
                        <div
                            className={`settingsDocs__card _parentForEdit _editBack ${
                                editName === 'passport' ? '_current' : ''
                            }`}
                        >
                            {((user._id === corporation.holder?._id &&
                                !hasVerificationCorporation) ||
                                user.idOfCurrentCorporation === 'admin') && (
                                <Edit
                                    name="passport"
                                    className="manualContent__cardEdit"
                                    editName={editName}
                                    handlerEditmode={this.passportHandlerEditmode}
                                    isLoader={editLoadingKey === 'passport'}
                                />
                            )}
                            {holder && (
                                <>
                                    <Passport
                                        globalUser={user}
                                        user={holder}
                                        userSave={holderSave}
                                        changeUser={this.changeHolder}
                                        setEditName={this.setEditName}
                                        setHandlerEditmode={(fn) => {
                                            this.passportHandlerEditmode = fn;
                                        }}
                                        getUser={() => {
                                            this.setState({
                                                holderSave: JSON.parse(JSON.stringify(holder)),
                                            });
                                        }}
                                        setLoadingKey={(key) => {
                                            this.setState({ editLoadingKey: key });
                                        }}
                                        inner={true}
                                    />
                                </>
                            )}
                        </div>
                        <div className={`settingsDocs__card _parentForEdit _editBack`}>
                            {holder && (
                                <>
                                    <UserMain
                                        user={holder}
                                        checkEditmode={() => false}
                                        inner={true}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(SettingsDocs);

SettingsDocs.propTypes = {
    corporation: PropTypes.object,
    getParent: PropTypes.func,
    inner: PropTypes.bool,
    updateCorporation: PropTypes.func,
    user: PropTypes.object,
};
