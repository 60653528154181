import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import Animate from '../../components/Animate.jsx';
import AuthSuccess from '../../components/AuthSuccess.jsx';
import Icon from '../../components/Icon.jsx';
import ImageLazy from '../../components/ImageLazy.jsx';
import Link from '../../components/Link.jsx';
import ListAbsoluteMain from '../../components/ListAbsoluteMain.jsx';
import Pages from '../../components/Pages.jsx';
import Forget from '../../components/crm/auth/Forget.jsx';
import Login from '../../components/crm/auth/Login.jsx';
import Registration from '../../components/crm/auth/Registration.jsx';

import handlerPopup from '../../functions/handlerPopup';
import setSpacesInText from '../../functions/setSpacesInText';

import pages from '../../redux/pages';

class Auth extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentMockup: 0,
        };

        this.setSuccessLogin = this.setSuccessLogin.bind(this);
        this.visibilityDocChange = this.visibilityDocChange.bind(this);

        this.parent = React.createRef();
    }

    pages = {
        login: {
            render() {
                return <Login setSuccessLogin={this.setSuccessLogin} />;
            },
        },
        registration: {
            render() {
                return <Registration setSuccessLogin={this.setSuccessLogin} />;
            },
        },
        forget: {
            render() {
                return <Forget setSuccessLogin={this.setSuccessLogin} />;
            },
        },
    };

    setSuccessLogin() {
        const { user } = this.props;

        return new Promise((resolve) => {
            this.setState({ isSuccessLogin: true }, () => {
                if (user?.corporations.length === 0) {
                    handlerPopup({ action: 'show', name: 'popupCorporation' });
                }

                resolve();
            });
        });
    }

    getNav() {
        return pages.filter((page) => page.parentName === 'public');
    }

    mockups = [
        {
            title: 'Цифровизация всех этапов<br/>для вашего бизнеса',
            description: 'От найма — до выплат',
            src: 'mockup-1.jpg',
        },
        {
            title: 'Мониторинг<br/>и управление процессами',
            description: 'Вся статистика у вас под рукой',
            src: 'mockup-2.jpg',
        },
        {
            title: 'Экспресс-заказы<br/>в пару кликов',
            description: '6+ служб доставки',
            src: 'mockup-3.jpg',
        },
        {
            title: 'Массовые выплаты<br/>с гибкими параметрами',
            description: 'Полный контроль финансов',
            src: 'mockup-4.jpg',
        },
    ];

    getMockups() {
        const { currentMockup } = this.state;

        return [{ key: currentMockup.toString(), ...this.mockups[currentMockup] }];
    }

    renderMockup({ item }) {
        return (
            <div className="auth__mockup">
                <div
                    className="auth__mockupTitle"
                    dangerouslySetInnerHTML={{ __html: setSpacesInText(item.title) }}
                />
                <div
                    className="auth__mockupDescription"
                    dangerouslySetInnerHTML={{ __html: setSpacesInText(item.description) }}
                />
                <div className="auth__mockupBox">
                    <ImageLazy
                        className="auth__mockupImage"
                        src={require(`../../img/crm/auth/${item.src}`)}
                    />
                </div>
            </div>
        );
    }

    handlerMockups({ dir, current, loop }) {
        let currentMockup = current;

        if (!loop) {
            this.setLoop();
        }

        if (dir === 'next') {
            currentMockup = this.state.currentMockup + 1;

            if (currentMockup === this.mockups.length) {
                currentMockup = 0;
            }
        }

        if (dir === 'prev') {
            currentMockup = this.state.currentMockup - 1;

            if (currentMockup === -1) {
                currentMockup = this.mockups.length - 1;
            }
        }

        this.setState({ currentMockup });
    }

    setLoop() {
        clearInterval(this.intervalId);

        this.intervalId = setInterval(() => {
            this.handlerMockups({ dir: 'next', loop: true });
        }, 5_000);
    }

    visibilityDocChange({ detail: { isActive } }) {
        if (isActive) {
            this.setLoop();
        } else {
            clearInterval(this.intervalId);
        }
    }

    componentDidMount() {
        this.setLoop();

        document.addEventListener('visibilityDocChange', this.visibilityDocChange);
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);

        document.removeEventListener('visibilityDocChange', this.visibilityDocChange);
    }

    render() {
        const { isSuccessLogin, currentMockup } = this.state;
        const nav = this.getNav();

        return (
            <div ref={this.parent} className="auth">
                <Animate className="auth__success _col" isShow={!!isSuccessLogin}>
                    <div className="auth__successBox">
                        <AuthSuccess />
                    </div>
                </Animate>
                <div className="auth__box">
                    <div className="auth__inner _row">
                        <div className="auth__block _col _info">
                            <img
                                src={require('../../img/crm/logo.svg').default}
                                alt=""
                                className="auth__logo"
                            />
                            <div className="auth__mockups">
                                <div className="auth__mockupsNav _row">
                                    <div
                                        className="auth__mockupsNavBtn _click _col"
                                        onClick={() => {
                                            this.handlerMockups({ dir: 'prev' });
                                        }}
                                    >
                                        <i className="auth__mockupsNavBtnIcon">
                                            <Icon name="arrow-prev-2" />
                                        </i>
                                    </div>
                                    <div className="auth__mockupsNavItems _row">
                                        {this.mockups.map((mockup, key) => (
                                            <div
                                                className={`auth__mockupsNavItem _click ${
                                                    currentMockup === key ? '_current' : ''
                                                }`}
                                                key={key}
                                                onClick={() => {
                                                    this.handlerMockups({ current: key });
                                                }}
                                            />
                                        ))}
                                    </div>
                                    <div
                                        className="auth__mockupsNavBtn _click _col"
                                        onClick={() => {
                                            this.handlerMockups({ dir: 'next' });
                                        }}
                                    >
                                        <i className="auth__mockupsNavBtnIcon">
                                            <Icon name="arrow-next-2" />
                                        </i>
                                    </div>
                                </div>
                                <ListAbsoluteMain
                                    className="auth__mockupsItems"
                                    items={this.getMockups()}
                                    renderItem={this.renderMockup}
                                    classNameItem="auth__mockup"
                                    prop="key"
                                    paramsParent={{ width: true }}
                                    styles={['height']}
                                    isNotParamsItem={true}
                                    currentItemKey={currentMockup.toString()}
                                    allItems={this.mockups.map((item, key) => key.toString())}
                                    resizeParent={document.querySelector('.body')}
                                />
                            </div>

                            <div className="auth__nav _row">
                                {nav.map((item) => (
                                    <div className="auth__navItem" key={item.name}>
                                        <Link className="auth__navLink" pageName={item.name}>
                                            {item.contentOfLink}
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="auth__block _col _content">
                            <div className="auth__forms">
                                <Pages
                                    classNamePage="auth__form"
                                    filter={(page) => page.parentName === 'auth'}
                                    pages={this.pages}
                                    context={this}
                                    fullHeight={false}
                                />
                            </div>
                            <div className="auth__copy">
                                livecargo CRM 2018-{new Date().getUTCFullYear()} ©
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(Auth);

Auth.propTypes = {
    user: PropTypes.object,
};
