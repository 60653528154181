import PropTypes from 'prop-types';

import React from 'react';

import getDaysInMonth from '../../functions/getDaysInMonth';
import getFormatPrice from '../../functions/getFormatPrice';
import getFormatedNumber from '../../functions/getFormatedNumber.ts';
import getUserIncline from '../../functions/getUserIncline';
import getUserInfo from '../../functions/getUserInfo';

import Pagenation from './Pagenation.jsx';
import Sign from './Sign.jsx';

class CorporationAct extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    renderPagination({ key }) {
        const { renderPagination } = this.props;

        const render = ({ pageCounter }) => (
            <Pagenation pageCurrent={key} pageCounter={pageCounter} />
        );

        return renderPagination({ key, render });
    }

    renderFoot() {
        const { renderFoot, pdf } = this.props;
        const { otherData, data } = pdf;
        const { docDate } = otherData;
        const { signId, signUserInfo, signExecutorInfo } = data;

        const render = () => (
            <>
                <Sign
                    id={signId}
                    docDate={docDate}
                    signUserInfo={signUserInfo}
                    signExecutorInfo={signExecutorInfo}
                />
            </>
        );

        return renderFoot({ render });
    }

    componentDidMount() {
        const { init } = this.props;

        init();
    }

    render() {
        const { pdf } = this.props;
        const { data, otherData, corporationInfo } = pdf;
        const { corporation, reportNumber } = data;
        const { startAmount, endAmount, replenishmentAmount, payAmount, commissionAmount } =
            corporation;
        const { year, month } = otherData;
        // const adminName = users.find((user) => user.role === 'holder')?.name;
        const lastDay = getDaysInMonth(month, year);
        const startDate = `01.${getFormatedNumber(month + 1)}.${year}`;
        const endDate = `${lastDay}.${getFormatedNumber(month + 1)}.${year}`;

        return (
            <div className="pdf__inner _col">
                {/* {getPages().map((page) => this.renderPagination({ key: page }))} */}
                <div className="pdf__innerBox">
                    <div className="pdf__elem _col">
                        <div className="pdf__corpActTitle">Отчет CRM LIVECARGO №{reportNumber}</div>
                    </div>
                    <div className="pdf__elem _col">
                        <div className="pdf__corpActHead _row">
                            <div className="pdf__corpActHeadItem">город Москва</div>
                            <div className="pdf__corpActHeadItem">{endDate}</div>
                        </div>
                    </div>
                    <div className="pdf__elem _col">
                        <div className="pdf__corpActDescription">
                            ООО «Лайвкарго» в лице Генерального директора Гомкцяна Карекина
                            Арутюновича направляет Отчет, {corporationInfo.name}, именуемое в
                            дальнейшем «Корпорация», в лице Генерального директора{' '}
                            {getUserInfo({
                                type: 'name',
                                user: getUserIncline({ user: corporationInfo.holder }),
                            })}
                            , действующего на основании Устава, принимает настоящий Отчет, о
                            нижеследующем.
                        </div>
                    </div>
                    <div className="JSpdfElem pdf__elem _col">
                        <div className="pdf__corpActInner">
                            <div className="pdf__corpActInnerBox">
                                <div className="pdf__corpActInnerCounter">1.</div>
                                <div className="pdf__corpActDescription">
                                    Информация об использовании Сервиса {'"'}CRM LIVECARGO{'"'} за
                                    период с&nbsp;{startDate} по&nbsp;{endDate}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="JSpdfElem pdf__elem _col">
                        <div className="pdf__corpActInner">
                            <div className="pdf__tableRow _row _info">
                                <div className="pdf__tableCol _info">
                                    Сальдо номинального счёта по состоянию на {startDate}, руб.
                                </div>
                                <div className="pdf__tableCol _sum">
                                    {getFormatPrice(startAmount)} руб.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="JSpdfElem pdf__elem _col">
                        <div className="pdf__corpActInner">
                            <div className="pdf__tableRow _row _info">
                                <div className="pdf__tableCol _info">
                                    1. Перечислено Корпорацией на номинальный счёт, руб.
                                </div>
                                <div className="pdf__tableCol _sum">
                                    {getFormatPrice(replenishmentAmount)} руб.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="JSpdfElem pdf__elem _col">
                        <div className="pdf__corpActInner">
                            <div className="pdf__tableRow _row _info">
                                <div className="pdf__tableCol _info">
                                    2. Списано у Корпорации в пользу 3-х лиц, руб.
                                </div>
                                <div className="pdf__tableCol _sum">
                                    {getFormatPrice(payAmount)} руб.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="JSpdfElem pdf__elem _col">
                        <div className="pdf__corpActInner">
                            <div className="pdf__tableRow _row _info">
                                <div className="pdf__tableCol _info">
                                    2.1. В том числе вознаграждение CRM LIVECARGO, руб.
                                </div>
                                <div className="pdf__tableCol _sum">
                                    {getFormatPrice(commissionAmount)} руб.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="JSpdfElem pdf__elem _col">
                        <div className="pdf__corpActInner _last">
                            <div className="pdf__tableRow _row _info">
                                <div className="pdf__tableCol _info">
                                    Сальдо номинального счёта по состоянию на {endDate}, руб.
                                </div>
                                <div className="pdf__tableCol _sum">
                                    {getFormatPrice(endAmount)} руб.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pdf__elem _col">
                        <div className="pdf__corpActInner _top">
                            <div className="pdf__corpActInnerBox">
                                <div className="pdf__corpActInnerCounter">2.</div>
                                <div className="pdf__corpActDescription">
                                    Итого вознаграждение CRM LIVECARGO составляет{' '}
                                    {getFormatPrice(commissionAmount)} руб. НДС не облагается
                                    в&nbsp;соответствии со ст. 145.1 НК РФ.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderFoot()}
            </div>
        );
    }
}

export default CorporationAct;

CorporationAct.propTypes = {
    pdf: PropTypes.object,
    init: PropTypes.func,
    getPages: PropTypes.func,
    pageCounter: PropTypes.object,
    renderPagination: PropTypes.func,
    renderFoot: PropTypes.func,
};
