import axios from 'axios';
import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import AnimateChange from '../../../components/AnimateChange.jsx';
import Filter from '../../../components/Filter.jsx';
import WindowActions from '../../../components/WindowActions.jsx';
import Windows from '../../../components/Windows.jsx';
import Table from '../../../components/crm/manual/Table.jsx';
import TableHead from '../../../components/crm/manual/TableHead.jsx';
import Back from '../../../components/crm/widget/Back.jsx';
import InfoHead from '../../../components/crm/widget/InfoHead.jsx';

import handlerPopup from '../../../functions/app/handlerPopup';
import getFilterModel from '../../../functions/getFilterModel';
import getHeaders from '../../../functions/getHeaders';
import getQueryFilter from '../../../functions/getQueryFilter';
import handlerWindow from '../../../functions/handlerWindow';
import setLocalFilter from '../../../functions/setLocalFilter';
import setNotification from '../../../functions/setNotification';

import HandlerFilter from '../../../classes/Filter';
import getFilter from '../../../requests/getFilter';
import getJoins from '../../../requests/getJoins';
import Main from './invites/Main.jsx';

class JoinsInvite extends Table {
    constructor(props) {
        super(props);
        this.state = {
            currentTab: localStorage.getItem('joins-invites-current-tab') || 'all',
        };

        this.setInfoHead = this.setInfoHead.bind(this);
        this.filterItem = this.filterItem.bind(this);
        this.handlerLoaderList = this.handlerLoaderList.bind(this);
        this.setTab = this.setTab.bind(this);
        this.handlerSocket = this.handlerSocket.bind(this);
        this.initCallbackFilter = this.initCallbackFilter.bind(this);
        this.renderFilter = this.renderFilter.bind(this);
        this.setFilter = this.setFilter.bind(this);
        this.renderWindowsEditActions = this.renderWindowsEditActions.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.getFilterModel = this.getFilterModel.bind(this);
        this.updateManual = this.updateManual.bind(this);
        this.changeCorporation = this.changeCorporation.bind(this);

        this.parent = React.createRef();
    }

    name = 'joins';

    stepCounter = 25;

    classNameItem = '.settingsUsersTable__row';

    getQueryForRequest(tab = this.state.currentTab) {
        const query = super.getQueryForRequest();

        query.params.push({ key: 'group', value: tab });

        return query;
    }

    getOtherQueryForRequest() {
        return this.getQueryForRequest();
    }

    getStartTabs() {
        const { currentTab } = this.state;

        return new Promise((resolve) => {
            if (currentTab === 'all' || this.isGetStartTabs) {
                resolve();
            } else {
                this.getTabs().then(() => {
                    resolve();
                });
            }

            this.isGetStartTabs = true;
        });
    }

    getItems() {
        // const { currentTab } = this.state;
        const query = this.getQueryForRequest();

        return new Promise((resolve) => {
            // this.getStartTabs().then(() => {
            getJoins(query).then(
                ({ joins, isLimit, tabs, counter }) => {
                    this.setItems(joins, false, isLimit, counter).then(() => {
                        this.setState({ tabs }, resolve);
                    });
                },
                () => null,
            );
            // });
        });
    }

    setTab(currentTab) {
        localStorage.setItem('joins-invites-current-tab', currentTab);

        this.setState({ currentTab }, this.updateItems);
    }

    setInfoHead({ key, value }) {
        this.setState((state) => {
            const newState = { ...state };
            let { infoHead } = newState;

            if (!infoHead) {
                infoHead = {};
            }

            infoHead[key] = value;

            newState.infoHead = infoHead;

            return newState;
        });
    }

    filterItem(item, key) {
        const { counterScroll } = this.state;

        return key < counterScroll;
    }

    handlerLoaderList(isShowLoaderList) {
        this.setState({ isShowLoaderList });
    }

    updateJoin({ id, fields }) {
        return new Promise((resolve) => {
            this.setState((state) => {
                const newState = { ...state };
                const items = JSON.parse(JSON.stringify(newState.items));

                const itemIndex = items.findIndex((item) => item._id === id);

                if (itemIndex !== -1) {
                    Object.keys(fields).forEach((key) => {
                        items[itemIndex][key] = fields[key];
                    });
                }

                newState.items = items;

                newState.updateItemKey = new Date().getTime();

                return newState;
            }, resolve);
        });
    }

    getTabs() {
        const query = this.getQueryForRequest('all');

        return new Promise((resolve) => {
            getJoins(query).then(({ tabs }) => {
                this.setState({ tabs }, resolve);
            });
        });
    }

    renderFilter({ filter, handlerFilter }) {
        return (
            <Filter
                className="_right"
                filter={filter}
                handlerFilter={handlerFilter}
                hideFilter={() => {
                    handlerWindow({
                        action: 'hide',
                        name: 'filter',
                    });
                }}
                callback={this.callbackFilter}
            />
        );
    }

    filterName = 'crm-filter-joins-invites';

    getFilterModel() {
        return getFilterModel({ stateFilter: this.state.filter, localName: this.filterName });
    }

    getFilter() {
        const localFilter = this.getFilterModel();

        getFilter({ name: 'joins' }).then(
            ({ blocks }) => {
                setLocalFilter({ localFilter, filter: blocks });

                this.initFilter({ blocks });
            },
            () => null,
        );
    }

    setFilter(filter) {
        localStorage.setItem(this.filterName, JSON.stringify(filter));

        this.setState({ filter });
    }

    initCallbackFilter(callbackFilter) {
        this.callbackFilter = callbackFilter.bind(this);
    }

    initFilter({ blocks = [] }) {
        this.handlerFilter.init({ blocks });
    }

    deleteItem() {
        this.updateItems();
    }

    renderWindowsEditActions({ items, joinId, phone, link }) {
        const callback = ({ key }) =>
            new Promise((resolve, reject) => {
                if (key === 'delete') {
                    axios
                        .delete(`${process.env.REACT_APP_API}/join?id=${joinId}`, {
                            headers: getHeaders(),
                        })
                        .then(
                            (res) => {
                                const { success } = res.data;

                                if (success) {
                                    this.deleteItem({ id: joinId });

                                    setNotification({ notification: 'success-change-info' });

                                    resolve();
                                } else {
                                    reject();
                                }
                            },
                            () => null,
                        );
                } else if (key === 'smsAgain') {
                    axios
                        .get(`${process.env.REACT_APP_API}/join?phone=${phone}&link=${link}`, {
                            headers: getHeaders(),
                        })
                        .then(
                            (res) => {
                                const { success } = res.data;

                                if (success) {
                                    setNotification({ notification: 'success-change-info' });

                                    resolve();
                                } else {
                                    reject();
                                }
                            },
                            () => null,
                        );
                } else if (key === 'logs') {
                    handlerPopup({
                        name: 'logsPopup',
                        isShow: true,
                        modelName: 'join',
                        modelId: joinId,
                    });

                    resolve();
                } else {
                    resolve();
                }
            });

        return (
            <WindowActions
                items={items}
                callback={callback}
                hide={() => {
                    handlerWindow({
                        action: 'hide',
                        name: 'userActions',
                    });
                }}
            />
        );
    }

    handlerSocket({ detail }) {
        const { name, data } = detail;
        const { currentTab, items } = this.state;

        if (name === 'join') {
            const { fields } = data;

            if (fields?.isCreate) {
                this.updateItems().then(
                    () => {
                        if (!currentTab !== 'all') {
                            this.getTabs();
                        }
                    },
                    () => null,
                );
            } else if (items) {
                this.updateJoin({ fields, id: data.id || data.joinId }).then(
                    () => {
                        this.getTabs();
                    },
                    () => null,
                );
            }
        }
    }

    updateManual({ detail: { id } }) {
        if (id === 'joins') {
            this.updateItems();
        }
    }

    changeCorporation() {
        const { main } = this.props;

        if (main) {
            this.updateItems();
        }
    }

    componentDidMount() {
        super.componentDidMount();

        this.getFilter();

        this.handlerFilter = new HandlerFilter({
            context: this,
        });

        document.addEventListener('changeCorporation', this.changeCorporation);
        document.addEventListener('getSocketData', this.handlerSocket);
        document.addEventListener('updateManual', this.updateManual);
    }

    componentWillUnmount() {
        document.removeEventListener('changeCorporation', this.changeCorporation);
        document.removeEventListener('getSocketData', this.handlerSocket);
        document.removeEventListener('updateManual', this.updateManual);
    }

    render() {
        const {
            infoHead = {},
            filter,
            items = [],
            isLimit,
            isReady,
            isShowLoaderList,
            tabs,
            currentTab,
            isLoadingFilter,
            counterUpdate,
            updateItemKey,
        } = this.state;
        const { counterChangePage, checkRights } = this.props;
        const { counter = 0 } = infoHead;
        const filteredItems = items.filter(this.filterItem);
        const filterQuery = getQueryFilter({ filter });

        return (
            <div ref={this.parent} className="widget _ready _fix _parent">
                <Windows name="filter" renderContent={this.renderFilter} />
                <Windows name="editActions" renderContent={this.renderWindowsEditActions} />

                <div className="widget__head _row">
                    <Back />
                    <div className="widget__headContent">
                        <div className="widget__headInner _row">
                            <InfoHead
                                title="Подключение"
                                className={counterChangePage > 0 ? '_withBack' : ''}
                            >
                                <div className="widget__headNameItemContent">
                                    Приглашения{' '}
                                    <AnimateChange
                                        prop={counter}
                                        type="_translateMedium"
                                        className="widget__headNameItemContentInner"
                                        classNameParent="_parent"
                                    >
                                        {`(${counter})`}
                                    </AnimateChange>
                                </div>
                            </InfoHead>
                            <div className="widget__headActions">
                                <TableHead
                                    name="joins"
                                    filter={filter}
                                    handlerFilter={this.handlerFilter}
                                    getParent={() => this.parent.current}
                                    checkRights={checkRights}
                                    counter={counter}
                                    filterQuery={filterQuery}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="widget__content _full">
                    <div className="widget__page _deep1 _full _show">
                        <div className="widget__pageBox">
                            <div className="widget__pageInner" key={counterUpdate}>
                                <Main
                                    items={filteredItems}
                                    getMoreItems={this.getMoreItems}
                                    isLimit={isLimit}
                                    stepCounter={this.stepCounter}
                                    isReady={isReady}
                                    handlerLoaderList={this.handlerLoaderList}
                                    isShowLoaderList={isShowLoaderList}
                                    tabs={tabs}
                                    currentTab={currentTab}
                                    setTab={this.setTab}
                                    isLoadingFilter={isLoadingFilter}
                                    counterUpdate={counterUpdate}
                                    updateItemKey={updateItemKey}
                                    getParent={() => this.parent.current}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        counterChangePage: state.counterChangePage,
    };
}

export default connect(mapStateToProps)(JoinsInvite);

JoinsInvite.propTypes = {
    counterChangePage: PropTypes.number,
    checkRights: PropTypes.func,
    main: PropTypes.bool,
    disabled: PropTypes.bool,
};
