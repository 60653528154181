import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import getUserInfo from '../../../functions/getUserInfo';

import Animate from '../../Animate.jsx';
import Icon from '../../Icon.jsx';
import Link from '../../Link.jsx';
import Loader from '../../Loader.jsx';
import StatusModel from '../StatusModel.jsx';

class OrderDetailsCrewExecutorInList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    orderActions = ['add', 'delete'];

    getInfoRoute() {
        const { mapInfo } = this.props;

        if (mapInfo) {
            const { distance, minutes, hours } = mapInfo;

            const distanceString = `${distance}км`;
            const timeString =
                hours === 0
                    ? (minutes === 0 && `<1мин`) || `${minutes}мин`
                    : `${hours + parseFloat((minutes / 60).toFixed(1))}ч`;

            return `≈${distanceString}, ${timeString}`;
        }

        return null;
    }

    render() {
        const {
            _id,
            type,
            firstName,
            secondName,
            thirdName,
            phone,
            rate,
            infoCars = [],
            handlerChoice,
            isActive,
            getCondForChange,
            addWorkerRoleForDriver,
            isDriverForWorkerRole,
            getCondForWorkerRoleForExecutor,
            car,
            onOrder,
            mapInfo,
            coords,
            canWorker,
            isOnline,
        } = this.props;

        const infoCar = infoCars.find((item) => item._id === car);
        const { nameMark, nameModel } = infoCar || {};
        const description =
            type === 'driver'
                ? `${nameMark}, ${nameModel}`
                : type === 'courier'
                  ? 'Курьер'
                  : 'Грузчик';
        const infoRouteString = this.getInfoRoute();
        const status = onOrder ? 'onOrder' : isOnline ? 'isOnline' : 'isOffline';
        const statuses = [
            { key: 'onOrder', content: 'На заказе' },
            { key: 'isOnline', content: 'В сети' },
            { key: 'isOffline', content: 'Не в сети' },
        ];

        return (
            <div
                className={`orderDetailsCrewExecutorInList ${isActive ? '_active' : ''} ${
                    !getCondForChange() ? '_disabled' : ''
                }`}
            >
                <div className="orderDetailsCrewExecutorInList__inner _row">
                    <Link
                        className="orderDetailsCrewExecutorInList__preview"
                        pageName="manual-executors-inner"
                        ids={{ 2: _id }}
                    >
                        {type === 'driver' && canWorker && (
                            <Animate
                                className={`orderDetailsCrewExecutorInList__previewBtn _col _click ${
                                    isDriverForWorkerRole ? '_active' : ''
                                }`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    addWorkerRoleForDriver(_id);
                                }}
                                isShow={getCondForChange() && getCondForWorkerRoleForExecutor()}
                            >
                                <i className="orderDetailsCrewExecutorInList__previewBtnIcon">
                                    <Icon name="kg" />
                                </i>
                            </Animate>
                        )}
                        <div className="orderDetailsCrewExecutorInList__previewInner _col _click">
                            <i className="orderDetailsCrewExecutorInList__previewDone">
                                <Icon name="accept" />
                            </i>
                        </div>
                    </Link>
                    <div className="orderDetailsCrewExecutorInList__content _row">
                        <div className="orderDetailsCrewExecutorInList__contentItem _col _left">
                            <div className="orderDetailsCrewExecutorInList__name">
                                {getUserInfo({
                                    type: 'fullnameShort',
                                    user: {
                                        firstName,
                                        secondName,
                                        thirdName,
                                    },
                                })}
                            </div>
                            <p className="orderDetailsCrewExecutorInList__description">
                                {description}
                            </p>
                            <p className="orderDetailsCrewExecutorInList__phone">{phone}</p>
                        </div>
                        <div className="orderDetailsCrewExecutorInList__contentItem _col _right">
                            <div className="orderDetailsCrewExecutorInList__status">
                                <StatusModel
                                    status={status}
                                    statuses={statuses}
                                    name="orderExecutor"
                                />
                            </div>
                            <div
                                className={`orderDetailsCrewExecutorInList__route ${
                                    mapInfo || !coords || coords.length !== 2 ? '_active' : ''
                                }`}
                            >
                                <Animate
                                    className="orderDetailsCrewExecutorInList__routeLoader"
                                    isShow={!mapInfo && coords && coords.length === 2}
                                >
                                    <Loader className="_main" isShow={true} />
                                </Animate>
                                <div className="orderDetailsCrewExecutorInList__routeInner">
                                    {infoRouteString || '–'}
                                </div>
                            </div>

                            <div className="orderDetailsCrewExecutorInList__rate _row">
                                <i className="orderDetailsCrewExecutorInList__rateIcon">
                                    <Icon name="star" />
                                </i>
                                {rate || '–'}
                            </div>
                        </div>
                    </div>
                    <Animate
                        className="orderDetailsCrewExecutorInList__action"
                        isShow={getCondForChange()}
                        isSmoothShow={true}
                    >
                        <div
                            className={`action _col _animate ${isActive ? '_delete' : '_add'}`}
                            onClick={() =>
                                handlerChoice({ _id, type, action: isActive ? 'delete' : 'add' })
                            }
                        >
                            {this.orderActions.map((action) => (
                                <Animate
                                    key={action}
                                    className={`action__icon _${action}`}
                                    isShow={action === 'add' ? !isActive : !!isActive}
                                >
                                    <Icon name={action} />
                                </Animate>
                            ))}
                        </div>
                    </Animate>
                </div>
            </div>
        );
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(OrderDetailsCrewExecutorInList);

OrderDetailsCrewExecutorInList.propTypes = {
    _id: PropTypes.string,
    type: PropTypes.string,
    firstName: PropTypes.string,
    secondName: PropTypes.string,
    thirdName: PropTypes.string,
    phone: PropTypes.string,
    infoCars: PropTypes.array,
    handlerChoice: PropTypes.func,
    isActive: PropTypes.bool,
    getCondForChange: PropTypes.func,
    addWorkerRoleForDriver: PropTypes.func,
    isDriverForWorkerRole: PropTypes.bool,
    getCondForWorkerRoleForExecutor: PropTypes.func,
    car: PropTypes.string,
    status: PropTypes.string,
    rate: PropTypes.string,
    mapInfo: PropTypes.object,
    coords: PropTypes.array,
    canWorker: PropTypes.bool,
    onOrder: PropTypes.bool,
    isOnline: PropTypes.bool,
};
